:root {
  scroll-behavior: smooth;
}
@media screen and (min-width: 800px) {
  :root {
    font-size: 1.1vw;
  }
}
strong {
  margin-right: 4px;
}
/* .flex {
  padding-left: 8px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.grow {
  flex-grow: 1;
  width: 50%;
} */
